/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"

import "./layout.css"
import { Helmet } from "react-helmet"

const Layout = ({ children }) => {

  return (
    <div className="h-screen">
      <Helmet>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link href="https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400&display=swap" rel="stylesheet" />
      </Helmet>
      <div  className="flex justify-center flex-col bg-gray-200 dark:bg-gray-900 text-gray-600 dark:text-gray-200 h-full items-center font-body">
        <main className="flex justify-center flex-col items-center">{children}</main>
      </div>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
